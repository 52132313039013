var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiselect", {
    attrs: {
      openDirection: "bottom",
      options: _vm.options,
      placeholder: "Select a payer group",
      label: "name",
      searchable: true,
      "show-labels": false,
      "track-by": "payer_group_id",
    },
    model: {
      value: _vm.selectedGroup,
      callback: function ($$v) {
        _vm.selectedGroup = $$v
      },
      expression: "selectedGroup",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }