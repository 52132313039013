var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("back-button", { attrs: { to: "/admin/" } }, [
        _vm._v("Back to Settings"),
      ]),
      _c(
        "b-card",
        {
          staticClass: "pt-3",
          staticStyle: { "max-width": "1000px" },
          style: _vm.loading ? "cursor:wait" : "cursor:default",
        },
        [
          _c(
            "b-form-group",
            {
              attrs: { "label-cols-lg": "3", "label-size": "lg" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _vm._v(" Fee Schedule CSV Import "),
                      _c("b-form-text", { staticClass: "mt-2 mr-3" }, [
                        _c("div", { staticClass: "mb-3" }, [
                          _vm._v(
                            " Upload a CSV file containing fee schedule data. "
                          ),
                        ]),
                        _c("div", { staticClass: "mb-3" }, [
                          _vm._v(
                            " Schedules will apply to all payers in the selected payer group. "
                          ),
                        ]),
                        _c("div", { staticClass: "mb-3" }, [
                          _vm._v(" File column format: "),
                          _c("code", [
                            _vm._v(_vm._s(_vm.requiredColumnsString)),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "description",
                  fn: function () {
                    return [
                      _c("strong", [
                        _vm._v(
                          "Warning: Files temporarily limited to first 100 rows for testing."
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Practice",
                    "label-for": "practice-multiselect",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                  },
                },
                [
                  _c("practice-multiselect", {
                    attrs: { id: "practice-multiselect" },
                    model: {
                      value: _vm.selectedPractice,
                      callback: function ($$v) {
                        _vm.selectedPractice = $$v
                      },
                      expression: "selectedPractice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Payer Group",
                    "label-for": "payer-group-multiselect",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("payer-group-multiselect", {
                            attrs: {
                              id: "payer-group-multiselect",
                              "reload-options": _vm.reloadPayerGroupOptions,
                            },
                            model: {
                              value: _vm.selectedPayerGroup,
                              callback: function ($$v) {
                                _vm.selectedPayerGroup = $$v
                              },
                              expression: "selectedPayerGroup",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.manage-payer-groups",
                                  modifiers: { "manage-payer-groups": true },
                                },
                              ],
                              staticClass: "avo-primary-btn",
                              attrs: { variant: "outline-primary" },
                            },
                            [_vm._v("Manage Groups")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("payer-manage-groups-modal", {
                on: {
                  submitted: function ($event) {
                    _vm.reloadPayerGroupOptions++
                  },
                },
              }),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Year",
                    "label-for": "year-select",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "year-select", options: _vm.yearOptions },
                    model: {
                      value: _vm.year,
                      callback: function ($$v) {
                        _vm.year = $$v
                      },
                      expression: "year",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip",
                      value: _vm.fileUploadTooltip,
                      expression: "fileUploadTooltip",
                    },
                  ],
                  attrs: {
                    label: "File Upload",
                    "label-for": "file-upload",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                  },
                },
                [
                  _c("b-form-file", {
                    attrs: {
                      id: "file-upload",
                      state: Boolean(_vm.file),
                      placeholder: "Choose a file or drop it here...",
                      "drop-placeholder": "Drop file here...",
                    },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: _vm.success ? "success" : "danger",
                            dismissible: "",
                          },
                          model: {
                            value: _vm.showAlert,
                            callback: function ($$v) {
                              _vm.showAlert = $$v
                            },
                            expression: "showAlert",
                          },
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-3" }, [
                    _vm._v(
                      "Selected file: " + _vm._s(_vm.file ? _vm.file.name : "")
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }